import React, { useContext, useState, useEffect } from "react";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import {
  useFirebaseApp,
  useUser,
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocDataOnce,
} from "reactfire";
import "firebase/auth";
import "firebase/firestore";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";

function Login({ location }) {
  const firebase = useFirebaseApp();
  const firestore = useFirestore(firebase);
  console.log("Firebase Auth: " + firebase.auth);
  const [user, setUser] = useState({ email: "", password: "", error: "" });
  const [errorOccured, setErrorOccured] = useState(false);

  const [admin, setAdmin] = useState(false);

  let signedIn = false;
  let isNewUser = false;
  let cameFromProducts = false;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  console.log(
    "Window Output Login: " + window.localStorage.getItem("isSignedIn")
  );
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      await firestore
        .collection("Users")
        .where("email", "==", user.email)
        .get()
        .then((snapshot) => {
          // console.log("Verified: " + snapshot.docs[0].data().admin);

          if (
            snapshot.docs[0].data().verified == "empty" ||
            snapshot.docs[0].data().verified == "declined"
          ) {
            alert("You cannot login until the admin approves your account!");
          } else {
            firebase
              .auth()
              .signInWithEmailAndPassword(user.email, user.password)
              .then(function () {
                console.log("Login Data: " + snapshot.docs[0].data().admin);
                if (snapshot.docs[0].data().admin) {
                  window.localStorage.setItem("isSignedIn", true);
                  navigate("/admin/");

                  // setAdmin(true);
                  console.log("Login: true");
                } else {
                  window.localStorage.setItem("isSignedIn", true);

                  global.admin = false;
                  // setAdmin(false);

                  navigate("/");
                  console.log("Login: false");
                }
                // console.log("Signed In");
              })
              .catch(function (error) {
                console.log("Error: " + error.message);
                setErrorOccured(true);
                setUser({ ...user, error: error.message });
              });
          }
        });
    } else {
      setErrorOccured(true);
    }
  };

  if (location.state == null) {
    // console.log("Location: is empty");
    isNewUser = false;
  } else {
    if (location.state.comingFromProduct) {
      window.onpopstate = (e) => {
        console.log("Back Pressed");
        navigate("/Products/");
      };
    } else {
      isNewUser = location.state.newUser;

  
      if (location.state.from) {
        window.localStorage.setItem("isSignedIn", false);

        firebase.auth().signOut();
        // console.log("Signed Out");
      }

      if (location.state.source) {
        cameFromProducts = true;
      } else {
        cameFromProducts = false;
      }
    }

    // console.log("Location: " + JSON.stringify(location));
  }

  useEffect(() => {
    if(isNewUser){
      alert(
        "Your account was created. You can login after the admin approves your account."
      );
    }
  }, [isNewUser])
  function validateForm() {
    let formIsValid = true;

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (typeof user.email !== "undefined") {
      //regular expression for email validation
      if (user.email == "" || user.email == undefined) {
        formIsValid = false;
        user.error = "Please enter your email.";
      } else {
        if (!pattern.test(user.email)) {
          formIsValid = false;
          user.error = "Please enter valid email.";
        }
      }
    }

    if (user.password == "" || user.password == undefined) {
      formIsValid = false;
      user.error = "Please enter your password.";
    }

    return formIsValid;
  }

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value, error: "" });
  };
  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const getSignedIn = window.localStorage.getItem("isSignedIn");
  console.log("Window Login2: " + getSignedIn);
  if (getSignedIn == "true") {
    console.log("SignedIn" + admin);

    navigate("/");

    return null;
  } else {
    console.log("SignedIn2 " + admin);

    return (
      <Layout>
        <section className="product_section">
          <Container>
            <div>
              <h1>Log In</h1>
              <hr />
            </div>
          </Container>
        </section>
        <section className="login_section">
          <Container>
            <Row>
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                {cameFromProducts && (
                  <p className="errorMsg">Please login to access products.</p>
                )}
                <Form className="contact_form" onSubmit={handleFormSubmit}>
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      placeholder="Email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      placeholder="Password"
                    />
                  </FormGroup>
                  <p className="log_hover">
                    <a href="/ForgotPassword">Forgot Password?</a>
                  </p>
                  {errorOccured && user.error && (
                    <p style={{ color: "red" }}>{user.error}</p>
                  )}
                  <Button type="submit" className="login_btn">
                    Login
                  </Button>
                  <p className="log_hover">
                    <a href="/Register">New to Grey Space? Register Here!</a>
                  </p>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    );
  }
}

export default Login;
